@use 'sass:meta';

@layer tailwind-base, primeng, tailwind-components;

/**
 * Inject tailwind files inside layers so that primeng can be ordered correctly in the css layers. tailwind v4 may add layers and this will need to be looked at again.
 * https://github.com/tailwindlabs/tailwindcss/discussions/6694#discussioncomment-4716568
 */

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-components {
  @tailwind components;
}

@tailwind utilities;
// not used yet but might be required in future @tailwind variants;

@import "primeicons/primeicons.css";


html {
  font-size: 14px;
}

html, body { font-family: Inter, "Helvetica Neue", sans-serif; }
body { margin: 0;  max-width: 100% !important; }

